import styled from 'styled-components'

import { Section as SectionGS, Infos as InfosGS } from 'components/general-styles'
import { theme } from 'styles/theme'

const { media, colors, space, radii, borderWidths, fontSizes, fontWeights, zIndices } = theme

export const Section = styled(SectionGS)`
  padding-bottom: 0;

  @media (${media.tablet}) {
    padding-bottom: ${space['spacing-xxl']};
  }

  @media (${media.desktop}) {
    padding-bottom: 7.5rem;
  }
`

export const Infos = styled(InfosGS)`
  @media (${media.tablet}) {
    max-width: 31rem;
  }

  @media (${media.desktop}) {
    min-height: 27.188rem;
  }
`

export const Card = styled.div`
  height: 17.5rem;
  padding: ${space['spacing-sm']};
  border: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
  border-radius: ${radii['corner-radius-md']};
  background-color: ${colors['neutral-background-default']};
  cursor: grab;

  h3 {
    margin-bottom: 14px;
  }
`
export const CardCounter = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${space['spacing-xxl']};
  height: ${space['spacing-xxl']};
  margin-bottom: ${space['spacing-lg']};
  font-size: ${fontSizes['font-size-body-1']};
  font-weight: ${fontWeights['font-weight-bold']};

  svg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`

export const RightSideWrapper = styled.div<{ medium: boolean }>`
  position: relative;
  background: ${({ medium }) =>
    medium
      ? 'linear-gradient(0deg, rgba(0, 34, 75, 1) 72%, rgba(240, 240, 242, 1) 72%)'
      : 'linear-gradient(0deg, rgba(0, 34, 75, 1) 72%, rgba(255, 255, 255, 1) 72%)'};
  margin-right: -${space['spacing-md']};
  margin-left: -${space['spacing-md']};
  padding-bottom: ${space['spacing-xxxl']};
  padding-left: ${space['spacing-md']};

  > .ds-button {
    position: relative;
    z-index: ${zIndices[1]};
    display: flex;
    justify-content: center;
    width: calc(100% - ${space['spacing-lg']});
    margin-top: ${space['spacing-md']};
    margin-right: ${space['spacing-md']};
  }

  @media (${media.tablet}) {
    background: none;
    padding-bottom: ${space['spacing-md']};
    margin-top: ${space['spacing-xxxl']};

    > .ds-button {
      display: none;
    }
  }

  @media (${media.desktop}) {
    position: absolute;
    left: 50%;
    right: ${space['spacing-md']};
  }
`

export const CarouselWrapper = styled.div`
  overflow: hidden;

  swiper-container::part(container) {
    overflow: unset;
  }

  > div:first-child {
    width: 13rem;
  }

  .controls-wrapper {
    position: relative;
    z-index: ${zIndices[1]};
    margin-top: ${space['spacing-lg']};
    margin-right: ${space['spacing-md']};
  }
`

export const CarouselBg = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 16.5rem;
  border-radius: ${radii['corner-radius-md']} 0 0 ${radii['corner-radius-md']};
  background-color: ${colors['neutral-background-contrast']};
`
