export const cards = [
  {
    id: '01',
    title: 'Cotação',
    description:
      'Preencha nosso formulário online para que possamos indicá-lo a um de nossos consultores e garantir seu atendimento personalizado.'
  },
  {
    id: '02',
    title: 'Avaliação',
    description:
      'O seu perfil e o do seu imóvel são analisados de maneira segura e rápida. Aqui conseguimos oferecer a melhor solução para você.'
  },
  {
    id: '03',
    title: 'Assinatura',
    description: 'Depois do crédito aprovado, providenciamos os documentos e os emitimos para a sua assinatura.'
  },
  {
    id: '04',
    title: 'Dinheiro liberado',
    description: 'Com tudo certo e o contrato assinado, é hora de receber o seu dinheiro!'
  }
]
