import { FC, memo } from 'react'

import { Text, Button } from '@cash-me/react-components'

import useWindowSize from 'hooks/useWindowSize'
import { HowWorks as HowWorksModule } from 'src/presentation/modules/how-works'

import { cards } from './data'
import { HowWorksProps } from './how-works.model'

const HowWorks: FC<HowWorksProps> = memo(({ onClick }) => {
  const { isTablet } = useWindowSize()

  return (
    <HowWorksModule cards={cards} onClick={onClick}>
      <Text className="subtitle" size="caption" weight="medium" uppercase>
        Como funciona
      </Text>

      <Text as="h2" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
        Como solicitar o <br />
        <Text as="span" size="heading3" weight="medium" color="var(--colors-neutral-text-default)">
          empréstimo para condomínio?
        </Text>
      </Text>

      <Text className="description" color="var(--colors-neutral-text-weak)">
        O processo é muito simples. Pedimos o mínimo de <br />
        documentos e tudo acontece em 4 etapas.
      </Text>

      {isTablet && (
        <Button size="lg" onClick={onClick} variants="default" iconName="arrowRight">
          Solicitar Simulação
        </Button>
      )}
    </HowWorksModule>
  )
})

HowWorks.displayName = 'HowWorks'

export default HowWorks
